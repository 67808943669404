import * as React from "react";
import classnames from "classnames";

import { layout } from "../constants";

export const ProseContainer: React.FC<{ className?: string }> = (props) => (
  <div
    className={classnames(
      "prose sm:prose-lg xl:prose-xl mx-auto",
      layout.ySpacing,
      props.className
    )}
  >
    {" "}
    {props.children}
  </div>
);

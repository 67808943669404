import { PageProps } from "gatsby";
import * as React from "react";
import { Layout } from "../components/Layout";
import { SaleInfo } from "../components/BuyDialog";
import { Button } from "../components/Button";
import { ProseContainer } from "../components/ProseContainer";

export const FourOhFour: React.VFC<
  PageProps<{ wpPage: { saleInfo: SaleInfo } }>
> = (props) => {
  return (
    <Layout
      headerVariation="small"
      title="Deze pagina bestaat niet"
      hideFooter
      robots="noindex"
    >
      <ProseContainer>
        <h1>Deze pagina bestaat niet</h1>

        <div className="mt-20">
          <Button to="/">Terug naar de website</Button>
        </div>
      </ProseContainer>
    </Layout>
  );
};

export default FourOhFour;
